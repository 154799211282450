@tailwind base;
@tailwind components;
@tailwind utilities;

/* These classes are for the Slick React Slider for the Registration page.
Module CSS won't work to style the dots */
.customSlickDots {
  @apply absolute block w-full p-0 m-0 text-center -bottom-6 list-none;
}

.customSlickDots li {
  @apply relative inline-block w-5 h-5 my-0 mx-1 p-0 cursor-pointer;
}

.customSlickDots li button {
  @apply text-0 leading-0 block w-5 h-5 p-1 cursor-pointer text-transparent
    border-0 outline-none bg-transparent hover:outline-none focus:outline-none;
}

.customSlickDots li button:hover:before,
.customSlickDots li button:focus:before {
  @apply opacity-100;
}
.customSlickDots li button:before {
  font-family: "slick";
  content: "•";
  @apply text-xxs leading-5 absolute top-0 left-0 w-5 h-5 text-center opacity-25
    text-brand-900 antialiased;
}
.customSlickDots li.slick-active button:before {
  @apply opacity-75 text-brand-900;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
