.draftStripes {
  background-image: linear-gradient(
    135deg,
    #ffffff 25%,
    #fafafa 25%,
    #fafafa 50%,
    #ffffff 50%,
    #ffffff 75%,
    #fafafa 75%,
    #fafafa 100%
  );
  background-size: 56.57px 56.57px;
}

/* VerificationInput component overrides (Initials.tsx) */
.vi__container {
  font-family: "DM Sans";
  font-weight: 700;
}

.zig:after {
  background: linear-gradient(-45deg, transparent 16px, #ffffff 0),
    linear-gradient(45deg, transparent 16px, #ffffff 0);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 22px 32px;

  content: "";
  display: flex;
  height: 30px;
}
